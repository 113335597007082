header {
    padding: 156px 0 100px;
}

section {
    padding: 50px 0;
}

.navbar .container-fluid>.navbar-header {
    float: left;
    margin-right: 10px;
}

.navbar .navbar-nav {
    float: left;
    margin: 5px;
}

.nav>li {
    float: left;
}

.home-image {
    padding: 0px !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.home-image-left {
    float: left;
    margin-right: 10px;
}

.home-image-right {
    float: right;
    margin-left: 10px;
}